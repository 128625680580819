#current p{
    display: inline-block;
 margin-bottom: 0;
 color: #969696;
 font-family: "OpenSans-Semibold", sans-serif;
 font-size: 20px;
 text-align: center;
}
#current{
    text-align: start;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.progressBar{
    display: inline-block;
    width: 75%;
    text-align: end;
}
.progress{
    height: 2em;
    --bs-progress-bar-bg: var(--themeColor);
}
.checkIn{
    padding: 2em 1em;
}
.checkIn hr{
    border-top: 1px solid rgb(180, 178, 178);

}
.checkinHistory{
    display: flex;
    justify-content: space-between;
    padding: 1em 0;
}
.checkinDate{
    font-size: 20px;
    font-family: "OpenSans-Semibold",sans-serif;

}
.checkinTime{
    display: flex;
    justify-content: space-between;
    background-color: #f0f0f0;
    padding: 1em;
    margin-top: 1em;
    text-align: start;
}
.checkinTime p{
    display: inline-block;
    margin-bottom: 0;
    display: inline-block;

    font-family: "OpenSans-Semibold",sans-serif;
    color: #3e4046;

}
.checkinTime span{
    font-weight: 100;
}
.progress-bar-striped {
    background-image: linear-gradient(45deg,hsla(0,0%,100%,.15) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.15) 0,hsla(0,0%,100%,.15) 75%,transparent 0,transparent);
    background-size: 1rem 1rem;
}

@media screen and (max-width: 767px){

    .checkinDate{
        font-size: 18px;
    }
    #current p{
        font-size: 18px;
        text-align: start;
    }
    .progressBar{
width: 65%;
    }
    .request{
        transform: translate(-50%, 80%) !important; 
     
    }
    .selectType select{
        max-width: 160px !important;
    }
    }
    @media screen and (max-width: 583px){
        .checkinTime p{
            margin: 0 !important;
            display: block;
        }
      
     
    }