.chatDiv{
    margin: 2em;
    margin-bottom: 0;
    text-align: end;
    height: 75vh;
    overflow: scroll;
}
.messg{
    width: 46%;
    padding: 30px 20px;
    min-width: 300px;
    min-height: 100px;
    background: #d9d9d9;
    padding: 15px 20px;
        border-radius: 12px 15px 15px 0;
margin-bottom: 1em;
    max-width: 250px;
cursor: default;
}
.messg img{
width: 100%;
}
#txtDate{
    font-size: 10px;
    color: #fff;
    font-weight: 400;
margin-bottom: 0;
    text-align: end;
}
#txtAcc{

    font-family: "OpenSans-Semibold",sans-serif;
    font-size: 12px;
    text-align: start;
    color: #212529;
margin-bottom: 0.5em;
}
#txt{
    font-family: "OpenSans-Semibold",sans-serif;
    font-size: 14px;
    color: #6c6c6c;
    text-align: start;  
}
.send input[type="text"]{
    background: transparent none repeat scroll 0 0;
    border: none;
    color: #4c4c4c;
    font-size: 15px;
    min-height: 48px;
    width: 100%;
    outline: none;
    font-family: "OpenSans-Regular",sans-serif;
}
.send input::placeholder{
    font-family: "OpenSans-Regular",sans-serif;
 
}
.send{
    border: 1px solid #a2a2a2;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    border-radius: 30px;
    padding: 0 15px;
    background: #fff;
    position: relative;
}
.send .sendBtn{
    height: 33px;
    position: absolute;
    top: 8px;
    width: 33px;
    border-radius: 50%;
    border: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--themeColor);
}
    .sendBtn:hover{
        box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    }
    .attach{
        height: 33px;
        position: absolute;
        top: 8px;
        width: 33px;
        border-radius: 50%;
        border: none;
        color: rgb(73, 71, 71);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
    }
    .attach:hover{
        color: rgb(23, 23, 23);

    }
    .flip{
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }

    