@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200&family=Open+Sans:wght@300;400;500;600;700&display=swap');

.sideNav{
    background-color: #f2f6fe;
height: 100%;
padding: 2em;
text-align: start;
}
.sideNav select{
    font-family:"OpenSans-regular",sans-serif;
}
.profilePic{
    width: 72px;
    height: 72px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;

}
.chat{
    border: none;
    background: transparent;
    color: var(--themeColor);
cursor: pointer;
width: 100%;
text-align: end;
}
.links{
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 0 6px rgb(0 0 0 / 16%);
    position: relative;
    margin-top: 1em;
}
.links a{
    text-decoration: none;
}
.links p{
    color: #969fb2;
    margin: 20px 15px;
    font-family:"OpenSans-Bold",sans-serif;
    font-size: 14px;
    font-weight: 600;

}
.links p:hover{
    color: var(--themeColor);
  
}

.active p{
    color: var(--themeColor);
}
.links div{
    width: 100%;
    text-align: end;
}
.links select{
    cursor: pointer;




  
    border-radius: 10px;
    padding: 5px;
}
.profileInfo{
    text-align: start;
    margin: 30px 0;
    
}
.usrName{
    font-size: 18px;
    color: var(--themeColor);
    font-family: "OpenSans-Bold",sans-serif;
    margin: 0;
    margin-top: 0.2em;
}
.usrDescribe{
    font-size: 12px;
    color: #969696;
    font-family: "OpenSans-Regular",sans-serif;
}
.navText{
    font-size: 18px;
    color: #969696;
    margin: 0;
    font-family: "OpenSans-Bold",sans-serif;
}
.welcome{
   
    color: var(--themeColor);
    font-family: "OpenSans-Semibold",sans-serif;
}

.imgBorder{
    width: 80px;
    height: 80px;
    border-radius: 50%;
background-color: var(--themeColor);
display: flex;
justify-content: center;
align-items: center;
}