.percentage .circle div{
    background-color: var(--themeColor);
    border-radius: 50%;
    width: 85px;
    height: 85px;
    display: flex;
 
    flex-direction: column;
  justify-content: end;
}
.activeDepSec svg{
    color: var(--themeColor);  
}
.percentage .circle{
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background-color: white;
    margin: 0 5px;
    box-shadow: 0 3px 16px rgb(0 0 0 / 16%);
display: flex;
justify-content: center;
align-items: center;
}
.dashboardBody{
    position: relative;
 
}

.circle{
    color: white;
    font-family: "OpenSans-Semibold",sans-serif;
    font-size: 10px;
}
.percent{
    font-family: "OpenSans-Bold" ,sans-serif!important;
font-weight: 600;
    font-size: 20px !important;
    margin-bottom: 0;
}
.percentage{
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
 top: -60px;
}
.log{
 padding-top: 5em;
 margin-bottom: 5em;
}
.log .checkin{
    background-color: var(--themeColor);
border: none;
color: #fff;
    font-size: 16px;
  
    border: none;
    font-family: "OpenSans-Bold" ,sans-serif!important;
    font-weight: 600;
    padding: 15px;
    border: none;

width: 50%;
    border-radius: 15px;
    margin-bottom: 5em;
    max-width: 180px;
}
.log .checkin:hover{
  


}
.activeDepSec {
    width: 95%;
    margin: auto;
    background: #fafafa;
    box-shadow: 0 0 12px rgb(0 0 0 / 16%);
    padding-top: 50px;
    padding-bottom: 50px;
padding: 2em;
text-align: start;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;}

    .activeDepSec p{
text-align: start;
color: #1c1c1c;
    font-family: "OpenSans-Bold",sans-serif;
    font-size: 1rem;
    font-weight: 700;
    }
    .activeDepSec svg{
        position: relative;
        bottom: 1.5px;
    }
    .employee{
    display: inline-block;
    }
    .employee p{
        font-weight: 100;
        text-align: center;
font-family: "OpenSans-regular",sans-serif;
    }
    .activeDepSec hr{
        border-top: 1px solid rgb(180, 178, 178);
    }
    .checkoutBtns button{
        border: none;
    }
.checkoutBtns{
    display: flex;
    justify-content: center;
    margin-bottom: 2em;
}
    .checkoutBtns .pause{
   height: 60px;

 background-color: #7aa1f0;
padding: 10px;
width: 50px;
border-radius: 10px 0 0 10px;
}
.birthdayBtn{

    background-color: var(--themeColor);
        border: none;
        color: #fff;
            font-size: 16px;
          display: flex;
        justify-content: center;
            border: none;
            font-family: "OpenSans-Semibold" ,sans-serif!important;
            font-weight: 400;
            padding: 10px;
            border: none;
            border-radius: 15px;
            width: 50%;
            display: flex;
          margin: auto;
          max-width: 150px;
          margin-top: 0.5em;
}
.birthdayModal{
    transform: translate(-50%, -50%) !important;
    max-height: 300px;
    overflow-y: auto;
    padding: 1em !important;
}
.birthdayImg{
    position: relative;
}
.birthdayImg img{
    position: absolute;
    z-index: -1; 
    top: -24px;
    width: 60px;
}
.bdButton{
    text-align: end;
}
.bdButton svg{
 cursor: pointer;

}
.birthdayMsg{

    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -55%);
    border-radius: 20px;
    height: unset;
    padding: 1em;
    right: 2em;
    width: 500px;
    z-index: 12;
    position: absolute;
  
  
}
.mood{
    cursor: pointer;
    height: 90px;
    width: 90px;
    background-size: contain;
    background-repeat: no-repeat;
    margin: auto;
    background-position: center;
}
.moodPicker p{

    color: #7aa1f0;
    text-align: center;
    font-size: 22px;    font-family: "OpenSans-Semibold";
margin-bottom: 1em;
}


.moodPicker{

    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -55%);
    border-radius: 20px;
    height: unset;
    padding:  1em;
    right: 2em;
    max-width: 550px;
    z-index: 12;
    position: absolute;
  width: 90vw;
  
}
.sadRsn{
    margin-top: 1em;
}
.birthdayBtn:disabled{
    opacity: .6;
    cursor: not-allowed;
}
.sadRsn textarea{
    margin-bottom: 0;
    height: 120px;
}
.sadRsn p{
    color: #1a4f87;
    font-family: "OpenSans-Semibold";
    font-size: 1rem;
    font-weight: 400;
}
.moodPicker .col{
    padding: 0;
    text-align: center;
}
.birthdayMsg textarea{
    min-height: 120px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 0;
    color: #495057;
    max-width: 90vw;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.bdImg{
    height: 411px;
    width: 450px;
    max-width: 90vw;
    margin: auto;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
}
.bdImg p{
    color: var(--themeColor);
    position: absolute;
    right: 0;
    left: 0;
    bottom: 20%;
    margin: auto;
    font-family: "OpenSans-Semibold";
    text-align: center;
    font-size: 20px;
    max-width: 300px;


}
.pauseAr{
    border-radius: 0 10px 10px 0 !important;

}
.playAr{
    border-radius: 10px 0 0 10px !important;

}
.pending{
    margin: 1em 0;
    font-family: "OpenSans-Semibold",sans-serif;
    color: #ff2626;
}
.timer{
    box-shadow: 0 3px 26px rgb(0 0 0 / 16%);
    padding: 20px;
    min-width: 250px;
    border-radius: 15px;
    font-size: 1.8rem;
    text-align: center;
    color:  var(--themeColor);
    font-family: "OpenSans-Bold", sans-serif;
    font-weight: 600;
    max-width: 300px;
    margin: auto;
}
.checkout{
    height: 60px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    background: var(--themeColor);
    color: #fff;
    font-size: 16px;
    padding: 20px;
    border: none;
    padding: 0 3em;
    font-family: "OpenSans-Bold",sans-serif;
}
.pause img{
    width: 100%;
}
.checkoutBtns .play{
    height: 60px;
 
  background-color: #7aa1f0;
 padding: 10px;
 width: 50px;
 border-radius: 0 10px 10px 0;
 }
 .play img{
     width: 100%;
 }
 
 @media screen and (max-width: 554px){
    .bdImg p {
        bottom: auto!important;
        top: 54vw;
        font-size: 18px;
        max-width: 200px;
    }
    .bdImg{
      height: 83vw;  
    }
 }
 @media screen and (max-width: 442px){

    .mood{
      height: 20vw;
      width: 20vw;
    }
}
 @media screen and (max-width: 767px){

    .dashboardBody{
        height: 100%;
        margin-bottom: 2em;
    }
    .footer{
        bottom: unset;
    }
    .percentage{
        position: relative;
        top: unset;
        margin: 2em 0;
    }
    .chat{
        margin-bottom: 2em;
    }
    .log{
        top: unset;
    }
 }