.taskStatus{
    display: flex;
    justify-content: space-between;
    margin-bottom: 3em;
}
.taskStatus button{
    border: none;
    background-color: white;
    font-family: "OpenSans-Semibold",sans-serif;
 border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0,0,0,.16);
padding: 0.5em  2em;
}

.taskStatus button:hover{
    color: white;
    background-color:var(--themeColor);
}
.taskStatus .activeState{
    color: white !important;
    background-color:var(--themeColor)!important ;
    box-shadow:none  !important;

}
.addTask{

    color: #969696;
    font-size: 14px;
    padding: 5px;
    border: 2px solid #fdc60c;
    border-radius: 15px;
    background: transparent;
    font-family: "OpenSans-Semibold", sans-serif;
    cursor: pointer;
    display: block;
    text-align: center;
    max-width: 180px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-bottom: 1em;
}
.addTask svg{
   color: #fdc60c
}
.addTask:hover{
background-color: #fdc60c;
}
.addTask:hover svg{
color: white;
    }
.colorCode{
 text-align: start;
 margin-bottom: 2em;
}
.colorCode div{
background-color: red;
width: 50px;
height: 50px;
border-radius: 10px;
cursor: pointer;
display: inline-block;
}
.activeColor{
    border: 5px solid #707070
}



.colorCode span{
    margin: 0 1em;
}
.tasksBody{
    display: block !important;
    }

.flexTasks{
    display: flex;
    justify-content: space-between;
    
}
.changeState p{
    font-size: 18px;
    color: #3e4046;
    font-family: "OpenSans-Bold",sans-serif;
    font-weight: 600;
}
.changeState button{
    background-color: white;
  border-radius: 5px;
    border: 2px solid transparent;
    padding: 5px;
}
.changeState img{

    width: 35px;
    height: 35px;
}
.changeState span{
 margin: 0.5em;
}
.changeState #log:hover {
    border: 2px solid red ;

}
.changeState #inProgress:hover{
    border: 2px solid orange ;

}
.changeState #done:hover{
    border: 2px solid rgb(5, 202, 5) ;

}
.activeStatus{

    border-radius: 5px;
    border: 2px solid #f2f6fe!important;
    cursor: not-allowed!important;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
}
.activeStatus:hover{
    border: 2px solid #f2f6fe!important;
}
.taskDate{
    color: rgb(252, 234, 27);
    font-family: "OpenSans-Bold", sans-serif;
    font-weight: 600;
    margin-bottom: 0;
    font-size: 14px;
    margin-top: 0.5em;
}
.firstTask{
    border-radius: 30px 0 0 0!important;
    border-bottom: 0;
}

.taskCollapse .accordion-item{
    border-left: 10px solid rgb(252, 234, 27) ;
  
}

.borderAr{
    border-right: 10px solid rgb(252, 234, 27) !important;
    border-left: none !important;
}
.borderAr  .accordion-header{
    border-left: 1px solid rgba(0,0,0,.125) !important;
    border-right: none !important;

} 
.firstAr{
    border-radius: 0 30px 0 0 !important;

}

.taskCollapse .accordion-header{
    border-left: none !important;


}
.progBar p{
    color: #989898;
    font-family: "OpenSans-Bold",sans-serif;
    font-weight: 600;
}
.progBar{
    text-align: start;
    background-color: white;
    background: #fff;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);

}
.taskHistory {
text-align: start;
}
.taskHistory img{
    width: 40px;
    height: 40px;
}
.taskHistory p{
    display: inline-block;
}
.taskHistory div{
    margin-bottom: 2em;
}
.taskHistory p{
    font-family: "OpenSans-Bold",sans-serif;
    font-size: 14px;
    margin: 10px;
    color: #3e4046;
    font-weight: 600;
}
.historyNum{
    margin: 0 0.5em;
}

.taskHistory #log {
    color:red !important;

}
.taskHistory #inProgress{
    color:orange !important;

}
.taskHistory #done{
    color:rgb(5, 202, 5) !important;

}
.accordion{
    margin-bottom: 2em;
}