@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;600;700;800&display=swap');


.App {
  text-align: center;
}
.fluid{
  --bs-gutter-x: 3rem;

}
@media screen and (max-width: 766px){
  .fluid{
 
    --bs-gutter-x: 15px;
}
}
input[type="radio"] {
  cursor: pointer;
  accent-color: #633235;
  width: 20px;
  height: 20px;
}
 input{
   font-family: 'Cairo';
 }
 input::placeholder{
  font-family: 'Cairo';
}

.loader{
    display: flex;

    justify-content: center;
    align-items: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}