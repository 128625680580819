.login{
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    display: flex;
    align-items: center;
}
.logo{
    height: 190px;
    width: 150px;
    background-repeat: no-repeat;
    background-size: contain;
display: inline-block;
}
.loginForm{
    text-align: center;
    width: 50vw;
    margin: auto;
    max-width: 400px;
    position: relative;
   
}
#hello{
    color: #7aa1f0;
    font-family: "OpenSans-Semibold", sans-serif;
    text-align: start;
    font-size: 35px;
    margin-bottom: 0;
}
#signIn{
        font-family: "OpenSans", sans-serif;
        color: #fff;
        text-align: start;
        font-weight: 300;
        -webkit-font-smoothing: antialiased;
        font-size: 16px;
}
.forgetLogin{
    color: #fff;
    font-weight: 200;
    margin-top: 1em;

}
.loginForm a{
    text-decoration: none;
}
.forgetLogin:hover{
cursor: pointer;
text-decoration: underline;
}

.loginForm input{
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400; 
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    border: none;
    border-radius: 8px;
    height: 45px;
    margin-bottom: 1em;
    
}

.loginForm input:focus{
    outline: none;
    box-shadow: 3px 3px 6px rgba(25, 94, 233, 0.679);
 

}
.forget{
    text-align: end;
    color: white;
    text-decoration: underline;
    font-size: 12px;
  cursor: pointer;
    font-family: "OpenSans-Regular", sans-serif;
   margin-bottom: 2em;
   margin-top: 1em;
}
.eye{
    position: absolute;
    right: 15px;
    top: 11px;
    cursor: pointer;
}
.errMsg{
    margin-bottom: 1em;
   color:#dc3545 !important;
   text-align: start;
   width: 100%;
   font-family:  "OpenSans-Semibold", sans-serif !important;
   font-size: 12px !important;
   margin-top: 0.2em;
}
.errorMsgModel{
    color:#dc3545 ;

    font-family:  "OpenSans-Semibold", sans-serif;
    font-size: 12px;

}
.success{
    color: #18d610!important;
    font-family: "OpenSans-Bold"!important;
    font-size: 14px;
    text-align: start;
}
.invalid{
    border: 1.5px solid #dc3545 !important;
    margin-bottom: 0 !important;
    
}
.eyeAr{
    right: unset !important;
    left: 15px !important;
}
.loginForm button{
    background: #ffc60a;
    width: 100%;
    color: #fff;
    font-family: "OpenSans-Bold", sans-serif;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    border: none;
    border-radius: 8px;
    height: 45px;
}
.loginForm button:hover{
    background: #f7c31b;

}


@media screen and (max-width: 500px) {
    .loginForm{
width: 90vw;
 
    }}