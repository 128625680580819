.profileBody{
    width: 80%;
    margin: auto;
    margin-top: 2em;
    max-width: 600px;
}
.profileBtns{
    display: flex;
    justify-content: space-between;
}
.profileBtns a{
    text-decoration: none;
}
.usrData {
    text-align: center;
}
.noData{
    color: #c7c7c7;
    font-size: 18px;
    text-align: center;
    font-family: "OpenSans-Bold";
    margin: 250px auto;
}
.usrData .imgBorder{
    position: relative;
margin: auto;
width: 100px;
height: 100px;
}
.imgBorder button{
    position: absolute;
    border: none;
    display: flex;
    width: 35px;
    height: 35px;
    align-items: center;
    border-radius: 50%;
    background-color: #f2f6fe;
    color: #194f87;
    bottom: 0px;
    right: 0;
}
.imgBorder button:hover{
    box-shadow: 0 0 6px rgb(0 0 0 / 16%);

}
.usrData .profilePic{
    width: 93px;
    height: 93px;

}
.profileBtns button{
    background-color: var(--themeColor);
    border: none;
    color: #fff;
        font-size: 16px;
      display: flex;
      align-items: center;
        border: none;
        font-family: "OpenSans-Semibold" ,sans-serif!important;
        font-weight: 400;
        padding: 10px;
        border: none;
        border-radius: 15px;
    
}
.btn1:hover{

}
.btn2{
    background-color: #b7b4b4 !important;
  
    
}
.btn2:hover{
    background-color: #c7c7c7 !important;
  
    
}
#upload{
    background-color: var(--themeColor);
    border: none;
    color: #fff;
        font-size: 16px;
     
        border: none;
        font-family: "OpenSans-Semibold" ,sans-serif!important;
        font-weight: 400;
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        margin-top: 0.7em;
}
#upload:hover{
  
 
}
.profileBody table{
    width: 100%;

    color: #212529;
    margin-top: 1em;
    text-align: start;
    margin-bottom: 2rem;
}
.profileBody tr{
    border-top: 1px solid #dee2e6;
    
}
#edit{
    background: #ff7171;
    border: none;
    margin-bottom: 10rem;
    color: white;
    font-family: "OpenSans-Bold",sans-serif;
    font-weight: 600;
    padding: 10px 50px;
    border-radius: 5px;
}
#edit:hover{
    background: #fb7a7a;

}
.profileBody th{
    padding: 0.75rem;
    font-family: "OpenSans-Semibold",sans-serif;
    font-weight: 500;
    color: #3e4046;
    font-size: 18px;
}
.editPorfile{
    text-align: start;
    margin-bottom: 8em;

}
.editPorfile label{
    display: block;
    color: #3e4046;
    font-family: "OpenSans-Semibold",sans-serif;
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0.5em;
    margin-top: 1em;

}

.profileBody input{
    background: #f0f0f0;
    border-radius: 10px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    width: 100%;
    border: 1px solid #ced4da;
    line-height: 1.5;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.profileBody input:focus{

background: white;
}
.profileBody .eye{
top: 8px;
}
#save{
    background: var(--themeColor);
    border: none;
    margin-bottom: 10rem;
    color: white;
    font-family: "OpenSans-Bold",sans-serif;
    font-weight: 600;
    padding: 10px 50px;
    border-radius: 5px;
    display: block;
margin: auto;  
margin-top: 1em;
}
#save:hover{


}
.salarySheet{
    margin-top: 2em;
}
.salaryBreakdown div{
    display: flex;
   padding: 0 1em;
    justify-content: space-between;
}
.salaryBreakdown p{
    margin-bottom: 0;
}
.slryDate{
    display: block;
    font-family: "OpenSans-Bold",sans-serif;
    font-weight: 600;
    text-align: start;
    margin-bottom: 0;
    color: #16a4dc;
    
 
}
 hr{
    border-top: 1px solid rgb(185, 183, 183);
    margin: 1em 0;
}
.salaryBreakdown{
padding-top: 1em ;
    border-radius: 10px;
    box-shadow: 0 0 10px rgb(0 0 0 / 16%);
}
#net{
    color: #16a4dc;

    background-color: #16a4dc23;
border-radius: 0 0 10px 10px;
padding: 1em 1em;
}

@media screen and (max-width: 767px){

.salarySheet{
    margin: 2em 0;
}
.profileBtns{
    margin-bottom: 1em;
}

}