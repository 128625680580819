@font-face {
    font-family: 'OpenSans-Bold';
    font-style: normal;
    src: local('GE_SS_Two_Bold'),
    url("../../fonts/GE_SS_Two_Bold.otf") format('truetype');
}

@font-face {
    font-family: 'OpenSans-Semibold';
    font-style: normal;
    src: local('GE_SS_Two_Bold'),
    url("../../fonts/GE_SS_Two_Bold.otf") format('truetype');
}

@font-face {
    font-family: 'OpenSans-Regular';
    font-style: normal;
    src: local('GE_SS_Unique_Light'),
    url("../../fonts/GE_SS_Unique_Light.otf") format('truetype');
}

@font-face {
    font-family: 'OpenSans-Light';
    font-style: normal;
    src: local('GE_SS_Unique_Light'),
    url("../../fonts/GE_SS_Unique_Light.otf") format('truetype');
}

@font-face {
    font-family: 'arial';
    font-style: normal;
    src: local('arial'),
    url("../../fonts/arial.ttf") format('truetype');
}

@font-face {
    font-family: 'arialbd';
    font-style: normal;
    src: local('arialbd'),
    url("../../fonts/arialbd.ttf") format('truetype');
}