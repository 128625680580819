
.requests{
padding: 2em 1em;
}
.selectType{
    display: flex;
    justify-content: space-between;
}
.selectType select{
    margin: 0;
 width: 40%;
max-width: 300px;
cursor: pointer;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.selectType button{
    color: #969696;
    font-size: 14px;
    padding: 5px;
    border: 2px solid #fdc60c;
    border-radius: 15px;
    background: transparent;
    font-family: "OpenSans-Semibold", sans-serif;
   
    cursor: pointer;
    display: block;
    text-align: center;
    max-width: 180px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.selectType svg{
   color: #fdc60c; 
}
.selectType button:hover{

    background-color: #fdc60c; 
}
.selectType button:hover svg{
    color: white;   
}
.requestData div{
    display: flex;
    justify-content: space-between;
}
.requestData{
    box-shadow: 0 0 22px rgb(0 0 0 / 16%);
    border-radius: 15px;
    padding: 20px;
}
.requestData p{
    margin-bottom: 0;
}
.reqDate div{
    display: block;
}
.reqStart, .reqEnd{



    color: #3e4046;
    font-size: 16px;
  
    font-family: "arial";
    font-weight: 100;
}

#to{
    color: #14ade9;
    display: block;
    font-family: "OpenSans-Bold",sans-serif;
    margin: 10px auto;
  
font-weight: 600;
}
.reqHead{
color: #14ade9;
font-size: 18px;
font-family: "OpenSans-Bold",sans-serif;
font-weight: 600;

position: relative;}
.reqData{
    font-size: 16px;
    font-family: "OpenSans-Semibold",sans-serif;
    text-align: center;
  
}
.reqDate div{
    width: 50%;
    border-left: 1px solid #a5a5a5;

}
.reqData{
    width: 50%;
    border-left: 1px solid #a5a5a5;
}
.borderRight{
    border-left: none !important;
    border-right: 1px solid #a5a5a5;

}
.request label{
    color: #14ade9;
    font-weight: 600;
    font-family: "OpenSans-Bold",sans-serif;
    display: block;
    margin-bottom: 0.5em;
}
.request{
    transform: translate(-50%, -50%);
padding: 2em;
    text-align: start;
}
.request input, select,textarea{
    background: #f0f0f0;
    border-radius: 15px;
    border: 1px solid #ced4da;
margin-bottom: 1em;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
width: 100% !important;
}
.request textarea{
height: 100px;

}

.request input, select{
    cursor: pointer;
}
.arrow {
    display: flex;
    align-items: center;
    justify-content: center;
}
.arrow{
    position: relative;
    top: 7px;
}
.request button{
    background-color: var(--themeColor);
border: none;
color: #fff;
    font-size: 16px;
  display: flex;
justify-content: center;
    border: none;
    font-family: "OpenSans-Semibold" ,sans-serif!important;
    font-weight: 400;
    padding: 10px;
    border: none;
    border-radius: 15px;
    width: 50%;
    display: flex;
  margin: auto;
  max-width: 200px;

}
.request button:hover{

}
