#about{
    color: #7aa1f0;
    font-family: "OpenSans-Bold",sans-serif;
    font-weight: 600;
    text-align: start;
    font-size: 22px;
}
.help{
    margin:2em;
}
.helpType {
    display: inline-block;
}
.helpType div{
  width: 100px;
  height: 100px;
  color: #3e4046;
  background-color: #f0f0f0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.helpType div:hover{
    box-shadow:0 0 6px rgb(0 0 0 / 30%);

}
.help span{
    margin: 0 3em;
}
.helpType p{
    color: rgb(62, 64, 70);
    font-family: "OpenSans-Bold",sans-serif;
    color: #3e4046;
    font-weight: 600;
    margin-top: 20px;
}
.activeType div{
    color: #7aa1f0 !important;
    background-color: #eff4ff !important;
    box-shadow:0 0 6px rgb(0 0 0 / 30%);
height: 120px;
width: 120px;

}
.activeType p{
    color: #7aa1f0 !important;
   font-size: 18px;
}
.help textarea{
    width: 100%;
    background: #f0f0f0;
    border: none;
    box-shadow: 0 0 8px rgb(0 0 0 / 16%);
    min-height: 150px;
    padding: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "OpenSans-Regular",sans-serif;
    font-weight: 100;
}
.help textarea:focus{
background-color: white;
}
.help button{

background-color: var(--themeColor);
        border: none;
        color: #fff;
            font-size: 16px;
          display: flex;
        justify-content: center;
            border: none;
            font-family: "OpenSans-Semibold" ,sans-serif!important;
            font-weight: 400;
            padding: 10px;
            border: none;
            border-radius: 10px;
            width: 50%;
            display: flex;
          margin: auto;
          max-width: 200px;
        
        }
        .help button:hover{
      }
      .homeSwiper  .usrPic{
        pointer-events: none;
        
      }
      .homeSwiper .usrs p{
          cursor: default;
      }
        .contactUs{
            margin: 1em 0;
            display: flex;
            justify-content: space-between;
            background: #eff4ff;
            padding: 20px;
            align-items: center;
            border-radius: 10px;
            box-shadow: 0 0 6px rgb(0 0 0 / 16%);
        }
        .contactUs button{
            margin: unset !important;
        }
        .contactUs p{

    font-family: "OpenSans-Bold",sans-serif;
    font-weight: 600;
    color: #3e4046;
    margin-bottom: 0;
        }
        .contactUs a{
            text-decoration: none;
        }