
.accordion-button{
    background: #f2f6fe!important;
border: none !important;
padding: 0 !important;
align-items: center;
justify-content: space-between;
}
.accordion-button:not(.collapsed){
    box-shadow: none !important;
}
.accordion-button:focus {
  
    border-color: transparent !important;
    outline: none !important;
    box-shadow: 0 0 0 0.1rem transparent  !important;
  }
  
  .accordion-header{
    background: #f2f6fe!important;
    border: 1px solid rgba(0,0,0,.125) !important;
    padding:  1.25rem;
  

 border-top: none !important;
 border-radius:  0 0 0.25rem  0.25rem!important

 
}
.meetinngsBody #editMeeting{
    color: var(--themeColor) !important;
}
.meetinngsBody a{
    text-decoration: none;
}
.first {
    background: #f2f6fe!important;
    border: 1px solid rgba(0,0,0,.125) !important;
    padding: 1.25rem;
   
    border-radius: 1.25rem  1.25rem 0 0 !important


}
.accordion-body{
    border: 1px solid rgba(0,0,0,.125) !important;
border-style: none solid solid solid !important;
}
#meetingName{
margin-bottom: 0.5em;
    color: var(--themeColor);
    font-size: 16px;
    font-weight: 600;
    text-align: start;
    font-family: "OpenSans-Bold", sans-serif;

}
#meetingTime{
    color: #a3a3a3;
    font-family: "OpenSans-Bold", sans-serif;
    font-weight: 600;
margin-bottom: 0;
    font-size: 14px;
}
.accordion-button::after{
  display: none;
}
.collapsed .open{
    transform: rotate(180deg);
    transition: 0.5s;
    }
    .open{
        color: #706b6b !important;
        transition: 0.5s;

    }
    .meetingNo{
        text-align: start;
        margin-bottom: 0;
        margin-top: 1em;
        color: #3e4046;
        font-family: "OpenSans-Bold",sans-serif;
        font-weight: 600;
    }
    .meetingNote{
    
        color: #989898;
        text-align: start;
    }
    .meetingNote{
        font-family: "OpenSans-Regular",sans-serif;


    }

 

    .meetingMembers
    .imgBorder{
       position: relative;
     
    }
  
    .accordion-body{
   display: flex;
   justify-content: space-between;
    }
    .react-calendar__navigation{
        background-color: #7aa1f0;
    }
    button.react-calendar__navigation__label {
        color: #4263a7!important;
    }
    button.react-calendar__navigation__arrow {
        color: #fff!important;
    }
    .react-calendar{
        border: none !important;
        background: transparent !important
    }
    .calenderBody{
        background: #f2f6fe;
        padding: 10px;
    }
    .react-calendar__month-view__weekdays__weekday {
        color: #7aa1f0!important;
    }
    .react-calendar__tile--active {
        background: #7aa1f0!important;
        border-radius: 50px!important;
        color: white !important;
    }
    .react-calendar__tile--now {
        border-radius: 50%!important;
        color: #7aa1f0;
        background: transparent;
        border:2px solid #7aa1f0 !important;
    }
    
    .react-calendar__tile--now:hover {
        background: transparent !important;
        color: #7aa1f0 !important;
    }
    .history{
        text-align: start;
        color: #989898;
    font-family: "OpenSans-Bold",sans-serif;
    font-weight: 600;
    margin-bottom: 0.5em;
    }
    .hDiv{
        display: flex;
        justify-content: space-between;
    }
    .hName{
        color: #3e4046;
        font-family: "OpenSans-Semibold",sans-serif;
        font-size: 16px;
        margin-bottom: 0.5em;
    }
    .hDate{
        color: #ffa439;
        font-family: "OpenSans-Bold",sans-serif;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0.5em;


    }
    .addMeeting{
        width: 90%;
        margin: 2em auto 5em auto;
    
    }
    .meetingFlex{
        display: flex;
        justify-content: space-between;
    }
    .meetingFlex label{

        color: var(--themeColor);
        font-weight: 600;
        font-family: "OpenSans-Bold",sans-serif;
        display: block;
        margin-bottom: 0.5em;
    text-align: start;
    }

    .meetingFlex input, select,textarea{
        background: #f0f0f0;
        border-radius: 15px;
        border: 1px solid #ced4da;
    margin-bottom: 1.5em;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
    width: 80%;
    }
    .addMeeting textarea{
    height: 100px;
    width: 100% !important;
    }
    
    .meetingFlex input, select{
        cursor: pointer;
    }
    .addMeeting p{
        font-size: 22px;
    margin-bottom: 30px;
    color: var(--themeColor);
    font-family: "OpenSans-Bold",sans-serif;
    font-weight: 600;
text-align: start;
    }

    .meetingFlex div {
        width: 35%;
    }


    .meetingFlex div input{
        width: 100%;
    }
    .invite h1{
       
        color: var(--themeColor);
        font-weight: 600;
        font-family: "OpenSans-Bold",sans-serif;
        display: block;
        margin-bottom: 1em;
    text-align: start;
    font-size: 1rem; 
    }
    .usrs p{
        color: #989898;
        font-family: "OpenSans-Semibold",sans-serif;
        font-size: 1rem;
        font-weight: 400;
        text-align: center;
        cursor: pointer;

    }
    .usrs{
     display: flex;
     flex-direction: column;
     align-items: center;
 
    }
    .usrPic div:hover {
        box-shadow:0 0 6px rgb(0 0 0 / 60%);
    }
   .active .usrPic:hover {
        box-shadow:0 0 6px rgb(0 0 0 / 60%);
    }
   .active .usrPic{

        background-color: var(--themeColor);
        height: 90px;
        width: 90px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.5em;

    }
    .usrPic div{
margin: 0.5em;
        cursor: pointer;
        height: 90px;
        width: 90px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
        margin-bottom: 0.5em;
    }
    .active .usrPic div{
        height: 80px;
        width: 80px;
        cursor: pointer;
        border-radius: 50%;
        margin: 0;

    }
    .active   .usrPic div:hover {
        box-shadow:none;
    }
    .active p{
        color: var(--themeColor);
    }
    .swiper-button-next, .swiper-rtl .swiper-button-prev{
        width: 30px!important;
    height: 30px!important;
    color: #707070 !important;
    background-color: #f1f5fc;
    border-radius: 50%;
    }
    .swiper-button-next:after, .swiper-button-prev:after{
        font-size: 20px !important;
    }
    .swiper-button-prev, .swiper-rtl .swiper-button-next{
        width: 30px!important;
    height: 30px!important;
    color: #707070 !important;
    background-color: #f1f5fc;
    border-radius: 50%;  
    }
    .swiper-button-prev:after, .swiper-button-next:after{
        font-size: 15px !important;
   
        
    }
    .meetingForm button{

        background-color: var(--themeColor);
        border: none;
        color: #fff;
            font-size: 16px;
          display: flex;
        justify-content: center;
            border: none;
            font-family: "OpenSans-Semibold" ,sans-serif!important;
            font-weight: 400;
            padding: 10px;
            border: none;
            border-radius: 15px;
            width: 50%;
            display: flex;
          margin: auto;
          max-width: 200px;
        
        }
        .meetingForm button:hover{
        }        
     .meetingMembers{
         white-space: nowrap;
         width: 40%;
     }

    