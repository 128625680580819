@font-face {
    font-family: 'OpenSans-Bold';
    font-style: normal;
    src: local('OpenSans-Bold'),
    url("../../fonts/OpenSans-Bold.ttf") format('truetype');
  }
  
  @font-face {
    font-family: 'OpenSans-Semibold';
    font-style: normal;
    src: local('OpenSans-Semibold'),
    url("../../fonts/OpenSans-Semibold.ttf") format('truetype');
  }
  
  @font-face {
    font-family: 'OpenSans-Regular';
    font-style: normal;
    src: local('OpenSans-Regular'),
    url("../../fonts/OpenSans-Regular.ttf") format('truetype');
  }
  
  @font-face {
    font-family: 'OpenSans-Light';
    font-style: normal;
    src: local('OpenSans-Light'),
    url("../../fonts/OpenSans-Light.ttf") format('truetype');
  }
  
  @font-face {
    font-family: 'arial';
    font-style: normal;
    src: local('arial'),
    url("../../fonts/arial.ttf") format('truetype');
  }
  
  @font-face {
    font-family: 'arialbd';
    font-style: normal;
    src: local('arialbd'),
    url("../../fonts/arialbd.ttf") format('truetype');
  }
  