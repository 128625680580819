
.template{
    height: 100vh;
}
.topNav{
    background-color: var(--themeColor);
    height: 100px;
    background-size: contain;
    background-position: 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3em;
}
.companyImg{
    height: 80px;
    width: 80px;
    background-size: contain;
    background-position: 100%;
    background-repeat: no-repeat;
}


.footer{
    background-color: var(--themeColor);
    position: absolute;
    bottom: 0;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
padding: 0.7em;
}
.footer p{

    font-family: "OpenSans-Semibold",sans-serif;
    text-align: center;
    font-size: 12px;
    color: white;
    margin-bottom: 0;
}
.notContent{
    max-width: 90vw;
}
.notif{

}
 .count::before{

content: attr(data-count);
position: absolute;
top:-1em;
right: -0.5em;

display: flex;
justify-content: center;
align-items: center;

color: white;

background: red;
padding: 3px;
border-radius: 5px;

color: #fff;
 font-family: "arialbd";
 }
 .empty::before{
     padding: 0 !important;
 }
.count{
    position: relative;
    color: #fff;
    background-color: transparent;
    border: none;
    z-index: 10;
}
.notif div{
display: flex !important;
justify-content: space-between;
align-items: center;
font-size: 12px;
display: inline-block;
font-family: "OpenSans-Semibold",sans-serif;
font-weight: 600;
cursor: pointer;
padding: 1em 0.5em;
padding-bottom: 0;
}
.notifyDiv{
    cursor: pointer;
padding: 0.5em;
padding-bottom: 0;
}
.modal-content .notif:hover{
background-color: rgba(128, 128, 128, 0.088);
}
.modal-content hr{
    border-top: 0.5px solid grey;
    margin-bottom: 0;
}
.modal-content p{
    margin-bottom: 0;
}
.notifyDate{
    font-family: "arial";
    font-size: 10px;
    font-weight: 100;
}
.modal-content {
    position: absolute ;
    top: 4.5em;
    right: 2em;
  
    line-height: 1.4;
    background: white;
    padding: 14px 0.5em;
    border-radius: 20px;
  
    z-index: 12;

   display: unset !important;
height: 200px ;
width: 350px;
background: #fff;
overflow-x: scroll;
border-radius: 5px;
box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
z-index: 10;
}

.modalOverlay {
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 9;
}

.modalOverlay {
    background: transparent;
}
.notData{
 background-color: rgba(128, 128, 128, 0.721) !important;
z-index: 11;
}
.notContent{
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -180%);
    border-radius: 20px;
    height: unset;
    padding: 3em ;
width: 500px;
z-index: 12;

}
.notTitle{
    font-size: 20px;
    font-family: "OpenSans-Bold",sans-serif;
    font-weight: 600;
    color: var(--themeColor);

}
.notText{
    color: #3e4046;
    font-family: "OpenSans-Semibold",sans-serif;
    font-weight: 500;

}
.table  th {
 
    border-bottom: 2px solid #dee2e6 ;
    font-family: "OpenSans-Semibold",sans-serif;
    padding: 1em;
  color:  #212529;
    font-weight: 500;
}